import * as React from 'react';
import {i18n} from "../../config/i18n";
import {ISummary} from "../../interfaces";
import {InnerSummaryTable, SummaryTable} from "../summary-and-codes-tables/SummaryTable";
import {IInteractiveSummaryProps} from "./Summary"
import SingleVariableAggregation from "./SingleVariableAggregation";
import {LocaleContext} from "../../contexts/LocaleContext";
import {useContext} from "react";
import {formattedValue} from "../summary-and-codes-tables/SummaryTableRow";

interface Props {
    summary: ISummary,
    summary2: ISummary
    interactiveProps?: IInteractiveSummaryProps
}

const BasicData: React.FunctionComponent<Props> = (props) => {
    const {summary, summary2, interactiveProps} = props;
    const locale = useContext<string>(LocaleContext)

    i18n.locale = locale;

    const titles_by_value = {
        'numberOfPatientcases': i18n.t('num_cases'),
        'avgTariff': i18n.t('avg_tariff'),
        'sex': i18n.t('sex'),
        'numberOfDistinctSdx': i18n.t('distinct') + ' ' + i18n.t('additional_diagnoses'),
        'numberOfDistinctPdx': i18n.t('distinct') + ' ' + i18n.t('pdxs'),
        'numberOfDistinctTarpos': i18n.t('distinct') + ' ' + i18n.t('tarpos'),
        'avgSdx': i18n.t('avg_number') + ' ' + i18n.t('additional_diagnoses'),
        'avgSrg': i18n.t('avg_number') + ' ' + i18n.t('procedures'),
        'avgTarpos': i18n.t('avg_number') + ' ' + i18n.t('tarpos')
    };

    const values1 = ['numberOfPatientcases', 'avgTariff', 'sex'];
    const values2 = ['numberOfDistinctPdx', 'numberOfDistinctTarpos', 'avgTarpos']; // 'avgSrg', 'avgSdx', 'numberOfDistinctSdx'

    const options_by_value = {
        'numberOfPatientcases': {'round': null, 'type': null, 'key_names': null, 'key_ids': null, 'sd': null},
        'sex': {
            'round': 2,
            'type': 'collection',
            'key_names': [i18n.t('female'), i18n.t('male')],
            'key_ids': ['W', 'M'],
            'sd': null
        },
        'avgAgeDays': {'round': 2, 'type': null, 'key_names': null, 'key_ids': null, 'sd': null},
        'avgAgeYears': {'round': 2, 'type': null, 'key_names': null, 'key_ids': null, 'sd': 'sdAgeYears'},
        'avgTariff': {'round': 2, 'type': 'money'},
        'avgSdx': {'round': 2, 'type': null, 'key_names': null, 'key_ids': null, 'sd': null},
        'avgSrg': {'round': 2, 'type': null, 'key_names': null, 'key_ids': null, 'sd': null},
        'avgTarpos': {'round': 2, 'type': null, 'key_names': null, 'key_ids': null, 'sd': null},
        'numberOfDistinctPdx': {'round': null, 'type': null, 'key_names': null, 'key_ids': null, 'sd': null},
        'numberOfDistinctSdx': {'round': null, 'type': null, 'key_names': null, 'key_ids': null, 'sd': null},
        'numberOfDistinctTarpos': {'round': null, 'type': null, 'key_names': null, 'key_ids': null, 'sd': null}
    }

    const distinctHospitals = () => {
        const numHospitals = summary.commonHospitals.length;
        if (numHospitals < 1) {
            return <></>;
        }
        // @ts-ignore
        const topHospitalShare = numHospitals > 0 ? (summary.commonHospitals[0][1] / summary.numberOfPatientcases) : 0
        const hospitalShareClass = topHospitalShare < 0.5 ? 'table-success' : topHospitalShare < 0.75 ? 'table-warning' : 'table-danger';

        let content = <td className={hospitalShareClass + " text-end"} id="summary_hospitals_analysis">
            {numHospitals} <span title={i18n.t('top_hospital')}>
                                                    ({formattedValue(topHospitalShare, 2, 'percentage', '', locale)})
                                            </span>
        </td>

        if (summary2) {
            const numHospitals2 = summary2.commonHospitals.length;
            // @ts-ignore
            const topHospitalShare2 = numHospitals2 > 0 ? (summary2.commonHospitals[0][1] / summary2.numberOfPatientcases) : 0
            const hospitalShareClass2 = topHospitalShare2 < 0.5 ? 'table-success' : topHospitalShare2 < 0.75 ? 'table-warning' : 'table-danger';
            content =  <>
                {content}
                <td className={hospitalShareClass2 + " text-end"} id="summary_hospitals_analysis">
                    {numHospitals2} <span title={i18n.t('top_hospital')}>
                                                    ({formattedValue(topHospitalShare2, 2, 'percentage', '', locale)})
                                            </span>
                </td>
                <td className="table-secondary text-end" id="summary_hospitals_analysis">
                    {numHospitals - numHospitals2}
                </td>
            </>
        }

        return (<tr>
            <td>
                <strong>
                    {i18n.t('num_hospitals')}
                </strong>
            </td>
            {content}
        </tr>)
    }


    return (
        <div className="card mb-3">
            <div className="card-header">
                <strong>
                    {i18n.t('key_figures')}
                </strong>
            </div>
            <div className="card-body p-0">
                <div className="row">
                    <div className="col-sm-6">
                    <div className="table-responsive">
                            <SummaryTable
                                summary={summary}
                                summary2={summary2}
                                titles_by_value={titles_by_value}
                                values={values1}
                                options_by_value={options_by_value}
                                disableHeader={false}
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="table-responsive">
                            <table className="table mb-0" style={{marginTop: "-1px"}}>
                                <tbody>
                                <InnerSummaryTable
                                    summary={summary}
                                    summary2={summary2}
                                    titles_by_value={titles_by_value}
                                    values={values2}
                                    options_by_value={options_by_value}
                                    disableHeader={false}
                                />
                                {distinctHospitals()}
                                {interactiveProps &&
                                    <SingleVariableAggregation
                                        interactiveProps={interactiveProps}
                                        numCasesA={summary.numberOfPatientcases}
                                        numCasesB={summary2 ? summary2.numberOfPatientcases : 0}
                                    />
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BasicData
