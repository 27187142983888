import * as React from 'react';
import { useState } from 'react';
import { i18n } from "../../config/i18n";
import { useConsumer } from "../../hooks/useConsumer"
import Progressbar from "../progress/Progressbar";
import Alert from "../Alert";

interface Props {
    locale: string,
    importId: number,
    userName: number,
    inProgress: boolean
}

const defaultValues = {
    format: '',
    cases: 0,
    total: 0,
    current: 0,
    errors: '',
    date: '-',
    taxpointValue: null,
    stReha: true,
    applicationYear: '',
    tariffVersions: '',
    dataYear: '',
    status: '-',
    statusType: null
}

const ImportProgress: React.FunctionComponent<Props> = ({ locale, importId, userName , inProgress}) => {
    i18n.locale = locale;
    const [data, setData] = useState(defaultValues)

    useConsumer({
            channel: "ImportsChannel",
            id: importId,
            received: (receivedData) => {
                if (receivedData.statusType === 'success' && inProgress) {
                    location.reload();
                } else {
                    setData((prevState => {
                            const nextState = Object.assign(
                                {},
                                prevState,
                                receivedData,
                            )
                            if (receivedData.error) {
                                nextState.errors =  prevState.errors + receivedData.error
                            }
                            return nextState
                        }
                    ))
                }
            },
        }
    )

    return (
        <>
            <div id="update_infos">
                <div className="row">
                    <div className="col-lg-2">
                        <b>Format</b>
                    </div>
                    <div className="col-lg-2">
                        {data.format}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2">
                        <b>
                            {i18n.t('num_cases')}
                        </b>
                    </div>
                    <div className="col-lg-4">
                        {(data.cases === -1) ? 0 : data.cases}
                    </div>
                </div>
               <div className="row">
                    <div className="col-lg-2">
                        <b>{i18n.t('tpw')}</b>
                    </div>
                    <div className="col-lg-2">
                        {data.taxpointValue}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2">
                        <b>{i18n.t('data_year')}</b>
                    </div>
                    <div className="col-lg-2">
                        {data.dataYear}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2">
                        <b>{i18n.t('import_date')}</b>
                    </div>
                    <div className="col-lg-4">
                        {data.date}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2">
                        <b>{i18n.t('owner')}</b>
                    </div>
                    <div className="col-lg-4">
                        {userName}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 vertical-spacer">
                        <Alert type={data.statusType} style={{fontSize: '1.2em'}}>
                            <div dangerouslySetInnerHTML={{__html: data.status}}/>
                        </Alert>
                    </div>
                </div>
                <h4>{i18n.t('import')}</h4>
                <Progressbar
                    total={data.total || 0}
                    current={data.current}
                    unit=""
                    disabled={data.statusType === 'danger' || data.statusType === 'success'}
                />
                {data.errors != '' &&
                    <div className="row">
                        <div className="col-lg-12 vertical-spacer">
                            <h4>{i18n.t('import_errors')}</h4>
                        </div>
                        <div className="col-lg-12">
                        <textarea
                            className="form-control"
                            id="log_error"
                            rows={10}
                            value={data.errors}
                            readOnly={true}
                        />
                        </div>
                    </div>}
            </div>
        </>
    )
}

export default ImportProgress
