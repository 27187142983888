import * as React from 'react';
import { i18n } from "../../config/i18n";
import {ISummary} from "../../interfaces";
import {InnerSummaryTable} from "../summary-and-codes-tables/SummaryTable";
import {formattedValue} from "../summary-and-codes-tables/SummaryTableRow"
import { accessSummary } from "../../utils/summaryUtils";
import {drawBoxPlot} from "../../utils/chartsUtils";
import {useContext} from "react";
import {LocaleContext} from "../../contexts/LocaleContext";

interface Props {
    summary: ISummary,
    summary2: ISummary,
    binSize: number
}

const Costs: React.FunctionComponent<Props> = (props) => {
    const {summary, summary2} = props;

    const locale = useContext<string>(LocaleContext);
    //i18n.locale = useContext<string>(LocaleContext);
    const values = ['meanCosts', 'medCosts', 'sdCosts', 'sales', 'totalCosts', 'profit', 'coverageRatio'];
    const titles_by_values = {
        'meanCosts': i18n.t('mean_costs'),
        'medCosts': i18n.t('med_costs'),
        'sdCosts': i18n.t('sd_costs'),
        'sales': i18n.t('sales'),
        'totalCosts': i18n.t('costs'),
        'profit': i18n.t('profit'),
        'coverageRatio': i18n.t('coverage_ratio'),
        'yieldOtherTariffs': i18n.t('yield_other_tariffs'),
        'yieldTarmed': i18n.t('yield_tarmed')
    };
    const options_by_value = {
        'meanCosts': {'round': 0, 'type': 'money', 'key_names': null, 'key_ids': null, 'sd': 'sdCosts'},
        'medCosts': {'round': 0, 'type': 'money', 'key_names': null, 'key_ids': null, 'sd': null},
        'sdCosts': {'round': 0, 'type': 'money', 'key_names': null, 'key_ids': null, 'sd': null},
        'sales': {'round': null, 'type': 'money', 'key_names': null, 'key_ids': null, 'sd': null},
        'totalCosts': {'round': null, 'type': 'money', 'key_names': null, 'key_ids': null, 'sd': null},
        'profit': {'round': null, 'type': 'money', 'key_names': null, 'key_ids': null, 'sd': null},
        'coverageRatio': {'round': 1, 'type': 'percentage', 'key_names': null, 'key_ids': null, 'sd': null},
    };

    const calcCostHomogeneity = (summary) => (1 / (1 + (summary['sdCosts'] / summary['meanCosts'])))
    const calcCostHomogeneityClass = (homogeneity) => {
        if (homogeneity >= 0.67) {
            return 'table-double-success'
        } else if (homogeneity >= 0.6) {
            return 'table-success'
        } else if (homogeneity >= 0.5) {
            return 'table-warning'
        } else {
            return 'table-danger'
        }
    }

    const costsHomogeneity = calcCostHomogeneity(summary)
    const costsHomogeneityClass = calcCostHomogeneityClass(costsHomogeneity)
    const costsHomogeneity2 = summary2 ? calcCostHomogeneity(summary2) : 0.0
    const costsHomogeneityClass2 = summary2 ? calcCostHomogeneityClass(costsHomogeneity2) : ''

    function boxPlot(data, columnTitles, name) {
        if (!Array.isArray(data[0])) {
            return
        } else {
            drawBoxPlot(data, columnTitles, name)
            return (
                <>
                    <div id={name + "_chart_div"}></div>
                    <div id={"box_plot"}></div>
                </>)
        }
    }

    function simpleFormat(value, option) {
        return formattedValue(value, option['round'], option['type'], '', locale)
    }

    return (
        <div className="col-6">
            <div className="card">
                <div className="card-header">
                    <strong>
                        {i18n.t('key_figures_financial')}
                    </strong>
                    <span className="ms-2 text-secondary small">{i18n.t('cost_data_cases_only')}</span>
                </div>
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="table-responsive">
                                <table className="table mb-0" style={{marginTop: "-1px"}}>
                                    {summary2 ?
                                        <tbody>
                                            <InnerSummaryTable
                                                summary={summary}
                                                summary2={summary2}
                                                values={values}
                                                titles_by_value={titles_by_values}
                                                options_by_value={options_by_value}
                                                disableHeader={false}
                                            />
                                            <tr>
                                                <th>{i18n.t('costs_homogeneity')}</th>
                                                <td className={costsHomogeneityClass + " text-end"}>{formattedValue(costsHomogeneity, 2, 'number', '', locale)}</td>
                                                <td className={costsHomogeneityClass2 + " text-end"}>{formattedValue(costsHomogeneity2, 2, 'number', '', locale)}</td>
                                                <td className="table-secondary text-end">{formattedValue(costsHomogeneity - costsHomogeneity2, 2, 'number', '', locale)}</td>
                                            </tr>
                                        </tbody>
                                        : <>
                                        <tbody>
                                            <tr>
                                                <th></th>
                                                <th>{i18n.t('sales')}</th>
                                                <th>{i18n.t('costs')}</th>
                                                <th>{i18n.t("diff")}</th>
                                            </tr>
                                            <tr>
                                                <th>{i18n.t('average')}</th>
                                                <td>{simpleFormat(summary['avgSales'], options_by_value['meanCosts'])}</td>
                                                <td>{simpleFormat(summary['meanCosts'], options_by_value['meanCosts'])}</td>
                                                <td>{simpleFormat(summary['avgSales'] - summary['meanCosts'], options_by_value['meanCosts'])}</td>
                                            </tr>
                                            <tr>
                                                <th>{i18n.t('median')}</th>
                                                <td>{simpleFormat(summary['medSales'], options_by_value['medCosts'])}</td>
                                                <td>{simpleFormat(summary['medCosts'], options_by_value['medCosts'])}</td>
                                                <td>{simpleFormat(summary['medSales'] - summary['medCosts'], options_by_value['medCosts'])}</td>
                                            </tr>
                                            <tr>
                                                <th>{i18n.t('stddev')}</th>
                                                <td>{simpleFormat(summary['sdSales'], options_by_value['sdCosts'])}</td>
                                                <td>{simpleFormat(summary['sdCosts'], options_by_value['sdCosts'])}</td>
                                                <td>{simpleFormat(summary['sdSales'] - summary['sdCosts'], options_by_value['sdCosts'])}</td>
                                            </tr>
                                            <tr>
                                                <th>{i18n.t('total')}</th>
                                                <td>{simpleFormat(summary['sales'], options_by_value['totalCosts'])}</td>
                                                <td>{simpleFormat(summary['totalCosts'], options_by_value['totalCosts'])}</td>
                                                <td>{simpleFormat(summary['sales'] - summary['totalCosts'], options_by_value['totalCosts'])}</td>
                                            </tr>
                                            <tr>
                                                <th>{i18n.t('yield_other_tariffs')}</th>
                                                <td>{simpleFormat(summary['yieldOtherTariffs'], options_by_value['totalCosts'])}</td>
                                            </tr>
                                            <tr>
                                                <th>{i18n.t('yield_tarmed')}</th>
                                                <td>{simpleFormat(summary['yieldTarmed'], options_by_value['totalCosts'])}</td>
                                            </tr>
                                            </tbody>
                                            <tfoot className="table-info">
                                            <tr>
                                                <th>{i18n.t('coverage_ratio')}</th>
                                                <td colSpan={3}
                                                    className="text-center">{simpleFormat(summary['coverageRatio'], options_by_value['coverageRatio'])}</td>
                                            </tr>
                                            <tr className={costsHomogeneityClass}>
                                                <th>{i18n.t('costs_homogeneity')}</th>
                                                <td colSpan={3}
                                                    className="text-center">{formattedValue(costsHomogeneity, 2, 'number', '', locale)}</td>
                                            </tr>
                                            </tfoot>
                                        </>
                                    }


                                </table>
                            </div>

                            {summary.costsPlotbox &&
                            summary2 ? boxPlot(
                                    [summary.costsPlotbox, accessSummary(summary2, "costsPlotbox")],
                                    [i18n.t('costs') + ' ' + summary.name, i18n.t('costs') + ' ' + summary2.name],
                                    "costsBoxplot") :
                                boxPlot(
                                    [summary.yieldPlotbox, summary.costsPlotbox],
                                    [i18n.t('sales'), i18n.t('costs')],
                                    "costsBoxplot")
                            }
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            )
            }

            export default Costs
